import React from 'react';
import { Link } from 'gatsby';

import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';

const ChangelogPage = () => {
  return (
    <Layout header={true}>
      <SEO />
      <div className="ids-doc sg if block blog">
        <div className="if documentation-container">
          <h1 className="if heading largest ">Changelog</h1>
          <div className="if text layout column left">
            <p className="if text lead ">Here is the changelogs for If Design System.</p>
            <p className="if text body ">All breaking changes are communicated in the demos that we hold.</p>
            <p className="ids-doc if text disclaimer">
              All notable changes to ids-core up to v17 will be documented in these files. See{' '}
              <a href="https://conventionalcommits.org" className="ids-doc if">
                Conventional Commits
              </a>{' '}
              for commit guidelines.
            </p>
          </div>
        </div>
        <div className="ids-doc sg if block" id="content">
          <div className="if documentation-container">
            <h2 className="if heading small">CHANGELOG.md files</h2>
            <ul className="if ids-doc navigational cards three text">
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_git/ids-core?path=/CHANGELOG.md&version=GBv17&_a=contents"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">CHANGELOG.md for @ids-core</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_git/ids-js?path=/CHANGELOG.md"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">CHANGELOG.md for @ids-js</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_git/ids-wc?version=GTv0.7.5&path=/CHANGELOG.md"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">CHANGELOG.md for @ids-wc</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_git/ids-react?path=/CHANGELOG.md&version=GTv0.17.10&_a=contents"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">CHANGELOG.md for @ids-react</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="ids-doc sg if block" id="content">
          <div className="if documentation-container">
            <h2 className="if heading small">Changelogs in Wiki</h2>
            <ul className="if ids-doc navigational cards three text">
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_wiki/wikis/digitalsolutions-guybrush.wiki/638/Core-Changelog"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">Changelog in Wiki for @ids-core</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_wiki/wikis/digitalsolutions-guybrush.wiki/3209/JS-Changelog"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">Changelog in Wiki for @ids-js</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_wiki/wikis/digitalsolutions-guybrush.wiki/3211/WC-Changelog"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">Changelog in Wiki for @ids-wc</span>
                </a>
              </li>
              <li className="if">
                <a
                  target="_blank"
                  href="https://dev.azure.com/if-it/If%20Design%20Hub/_wiki/wikis/digitalsolutions-guybrush.wiki/3213/React-Changelog"
                  rel="noreferrer noopener"
                  className="if ids-doc navigational-card text box">
                  <span className="if title">Changelog in Wiki for @ids-react</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangelogPage;
